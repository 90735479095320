import OSS from 'ali-oss'
import axios from 'axios'
const mapOssConfig = (Credentials) => {
  return {
    region: 'oss-accelerate',
    accessKeyId: Credentials.AccessKeyId,
    accessKeySecret: Credentials.AccessKeySecret,
    stsToken: Credentials.SecurityToken,
    bucket: 'retinavessel-files'
  }
}

async function getSTS () {
  let result = null
  try {
    result = await axios.get('https://get-sts-sts-server-cmfwmkhkhv.cn-hongkong.fcapp.run')
  } catch (err) {
    console.log(err)
  }
  return result && result.data
}

const putBlob = (credentials) => async (blob, fileName, options) => {
  const ossConfig = mapOssConfig(credentials)
  let result = null
  const client = new OSS({ ...ossConfig })
  try {
    result = await client.put(fileName, blob, { type: 'text/plain' })
  } catch (e) {
    console.log(e)
    throw e
  }
  return result
}

const putJSON = (credentials) => async (fileName, json, options) => {
  let result = null
  const ossConfig = mapOssConfig(credentials)
  const client = new OSS({ ...ossConfig })
  json = new OSS.Buffer(JSON.stringify(json))
  try {
    result = client.put(fileName, json)
  } catch (e) {
    console.log(e)
    throw e
  }
  return result
}

const getObject = (credentials) => async (fileName) => {
  let result = null
  const ossConfig = mapOssConfig(credentials)
  const client = new OSS({ ...ossConfig })
  try {
    result = client.get(fileName)
  } catch (e) {
    console.log(e)
    throw e
  }
  return result
}
const deleteObject = (credentials) => async (fileName) => {
  let result = null
  const ossConfig = mapOssConfig(credentials)
  const client = new OSS({ ...ossConfig })
  try {
    result = client.delete(fileName)
  } catch (e) {
    console.log(e)
    throw e
  }
  return result
}

const deleteMulti = (credentials) => async (list) => {
  let result = null
  const ossConfig = mapOssConfig(credentials)
  const client = new OSS({ ...ossConfig })
  try {
    result = await client.deleteMulti(list)
    console.log(result)
  } catch (e) {
    console.log(e)
  }
}

const getList = (credentials) => async (prefix) => {
  let list = []
  let result = null
  let marker = null
  const ossConfig = mapOssConfig(credentials)
  const client = new OSS({ ...ossConfig })
  const maxKeys = 20
  do {
    try {
      result = await client.list({
        marker,
        prefix: prefix,
        'max-keys': maxKeys
      })
      marker = result.nextMarker;
      list = list.concat(result.objects)
    } catch (e) {
      console.log(e)
    }
  } while(marker)
  return list
}

export default {
  putBlob,
  getList,
  getObject,
  deleteObject,
  deleteMulti,
  putJSON,
  getList,
  getSTS
}
